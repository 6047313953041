import React from 'react';

const Header = () => {

  return (
    <div id="header">
      <h1>Dark Globe Development</h1>
    </div>
  );

}

export default Header;
